<template>
  <div class="row">
    <div class="col">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <Confirm ref="confirm"></Confirm>
      <transition name="slide">
        <div class="card">
          <div class="card-header">
            <strong>Materias Asignadas: {{ nombreDocente }}</strong>
            <div class=" card-header-actions">
              <form class="form-inline">
                <input id="user" type="hidden" value="<?php echo $user->id ?>"/>
                <b>Filas por p&aacute;gina</b>
                <select class="form-control" @change="sizeChange($event)"
                        v-model="filasPorPagina">
                  <option v-for="option in sizeoptions" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>

                <button class="btn btn-outline-dark mr-2" type="button"
                        @click="borrarFiltroBtnOnClick()">
                  <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                  Quitar Filtro
                </button>

                <button class="btn btn-outline-primary ml-2" data-html="true"
                        data-toggle="tooltip" title="Registrar nueva materia" type="button"
                        @click="nuevoBtnOnClick()">
                  <font-awesome-icon icon="fa-plus"/>
                  Nuevo
                </button>

                <button class="btn btn-outline-success ml-2" data-html="true"
                        data-toggle="tooltip"
                        title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>"
                        type="button"
                        @click="excelBtnOnClick()">
                  <font-awesome-icon icon="fa-file-excel"/>
                  Exportar a Excel
                </button>
              </form>
            </div>
          </div>
          <div class="card-body pt-0 pr-1 pb-0 pl-0">
            <JqxGrid :width="'100%'" ref="gridSystem"
                     :source="dataAdapter"
                     :autoheight="true" :autorowheight="false" :rowsheight="50"
                     :pageable="true"
                     :pagesize="parseInt(filasPorPagina)"
                     :virtualmode="true"
                     :rendergridrows="rendergridrows"
                     :columns="columns" :enablebrowserselection="true"
                     :enabletooltips="true" :filterable="true"
                     :showfilterrow="true"
                     :sortable="true"
                     :pagermode="'simple'" :localization="localization"
                     :theme="'bootstrap'" :columnsresize="true"/>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Toast from "@/views/notifications/Toast";
import Confirm from "@/views/notifications/Confirm";
import Alerts from "@/views/notifications/Alerts";

var sourceLst;
var address;
var modalPdf;
var pagesizeoptions = [6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'Docentesmateria',
  components: {
    Confirm,
    Alerts,
    Toast,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      items: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      filasPorPagina: pagesizeoptions[0],
      sizeoptions: [],
      message: '',
      showMessage: false,
      dismissSecs: 7,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      nombreDocente:'',
      sedeSeleccionada: localStorage.sedeSeleccionada,
      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'Gestion', datafield: "gestion", width: '7%', cellsalign:'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Gestión</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por Gestión"});
          }
        },
        {
          text: 'Semestre', datafield: "semestre", width: '6%', cellsalign:'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Semestre</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por Semestre"});
          }
        },
        {
          text: 'Materia', datafield: "materia", width: '32%',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Materia</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por Materia"});
          }
        },
        {
          text: 'Grupo', datafield: "grupo", width: '6%', cellsalign:'center',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "grupo"});
          }
        },
        {
          text: 'estudiantes_inscritos', datafield: "estudiantes_inscritos", width: '7%', cellsalign:'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Estudiantes<br/>Inscritos</div>';
          },
          cellclassname:function (row, columnfield, value) {
            if(value<1) {
              return 'text-danger text-bold';
            }
            else return 'text-success text-bold';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "grupo"});
          }
        },
        {
          text: 'Modificado por', datafield: "usuario", width: '17%', cellsalign:'center',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Modificado por</div>';
          },
          cellclassname:'text-small',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por usuario"});
          }
        },
        {
          text: 'Modificado el', datafield: "updated_at", width: '13%', cellsalign:'center',
          cellclassname:'text-small',
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por fecha de modificación"});
          }
        },
        {
          text: '', width: '6%',  cellsalign:'center',
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return '<a class="btn btn-warning m-0" href="/#/docentemateria/' + modalPdf.$route.params.id + '/' + rowdata.id + '/editar" title="Editar datos del docente">Editar</a>';
          }, filterable: false, sortable: false
        },
        {
          text: "", datafield: 'hojaruta', width: '6%', columntype: "button",
          filterable: false, sortable: false,  cellsalign:'center',
          cellclassname: "btn-red",
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return "Eliminar";
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            let html = '';
              modalPdf.$refs.alert.show('Eliminando...');
              axios.get(
                  address + '/api/docentemateria/' + rowData.id + '/eliminar?token=' + localStorage.getItem("api_token")
              )
                  .then((response) => {
                    modalPdf.$refs.alert.hide();
                    modalPdf.$refs.gridSystem.updatebounddata();
                  })
                  .catch(function (error) {
                    modalPdf.$refs.alert.hide();
                    modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                  });
          }
        },
      ],
      localization: {
        'emptydatastring': "No existen datos",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/docentemateria/' + this.$route.params.id + '/listar?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada;
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'id', type: "int"},
        {name: 'gestion', type: "string"},
        {name: 'materia', type: "string"},
        {name: 'semestre', type: "int"},
        {name: 'usuario', type: "string"},
        {name: 'grupo', type: "string"},
        {name: 'estudiantes_inscritos', type: "int"},
        {name: 'updated_at', type: "string"},
      ],
      data: {
        sede: this.sede
      },
      datatype: "json",
      root: "Rows",
      id: 'id',
      sortcolumn: 'gestion',
      sortdirection: 'desc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            i++;
          }
          if (i > 1) {
            this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  methods: {
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        return roles != null && roles.indexOf(roleUser) >= 0;
      }
    },
    getBadge(status) {
      return status === 'Active' ? 'success'
          : status === 'Inactive' ? 'secondary'
              : status === 'Pending' ? 'warning'
                  : status === 'Banned' ? 'danger' : 'primary'
    },
    excelBtnOnClick: function () {
      this.$refs.gridSystem.hidecolumn('documentos');
      this.$refs.gridSystem.hidecolumn('editar');
      this.$refs.gridSystem.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'Inscritos' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));
      this.$refs.gridSystem.showcolumn('documentos');
      this.$refs.gridSystem.showcolumn('editar');
      this.$refs.gridSystem.showcolumn('eliminar');
    },
    nuevoBtnOnClick: function () {
      this.$router.push({path: '/docentemateria/' + this.$route.params.id + '/nuevo'});
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    sizeChange: function (event) {
      try {
        modalPdf.$refs.alert.show("Actualizando datos");
        this.$refs.gridSystem.pagesize = parseInt(this.filasPorPagina);
        this.$refs.gridSystem.updatebounddata();
      } catch (e) {}
    },
    eliminar: function (docenteid, id) {
      modalPdf.$refs.confirm('', 'Eliminar este registro', function (docenteid, id) {
        this.$refs.gridSystem.updatebounddata();
      })
    },
  },
  computed: {},
  mounted() {
    modalPdf = this;
    console.log(modalPdf);
    this.$root.$on('sedeSeleccionada', data => {
      left.sede = data;
    });
    axios.get(
        address + '/api/docentemateria/' + modalPdf.$route.params.id + '/nombredocente?token=' + localStorage.getItem("api_token")
    )
        .then((response) => {
           modalPdf.nombreDocente=response.data.nombreDocente
        })
        .catch(function (error) {
        });
  },
}
</script>